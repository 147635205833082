const MDASH = '\u2014';

const CARRIERS = [
  'APC',
  'CANADA_POST',
  'DHL_ECOMMERCE',
  'DHL_EXPRESS',
  'FEDEX',
  'INTERNATIONAL_BRIDGE',
  'LANDMARK_GLOBAL',
  'LASERSHIP',
  'LOOMIS_EXPRESS',
  'NEWGISTICS',
  'ONTRAC',
  'OSM',
  'PUROLATOR',
  'UPS',
  'USPS',
  'FLEET_OPTICS',
  'UDS',
  'CDL',
  'VEHO',
  'SENDLE',
  'SMARTKARGO'
];

const VENDORS = [
  'APC',
  'CANADA_POST',
  'DHL_ECOMMERCE',
  'DHL_EXPRESS',
  'EASYPOST',
  'ENDICIA',
  'FEDEX',
  'INTERNATIONAL_BRIDGE',
  'LANDMARK_GLOBAL',
  'LASERSHIP',
  'LOOMIS_EXPRESS',
  'NEWGISTICS',
  'ONTRAC',
  'OSM',
  'PITNEY_BOWES',
  'POSTAGE_ONE',
  'PUROLATOR',
  'SHIPPO',
  'STAMPSCOM',
  'UPS',
  'ACI',
  'GLS',
  'QUIET_FEDEX',
  'UPS_CANADA',
  'FEDEX_CANADA',
  'DHL_EXPRESS_CANADA',
  'DHL_ECOMMERCE_CANADA',
  'FLEET_OPTICS',
  'UDS',
  'JITSU',
  'CDL',
  'VEHO',
  'USPS',
  'MU',
  'IN_EXPRESS',
  'SENDLE',
  'FEDEX_INTERNATIONAL',
  'UPS_INTERNATIONAL',
  'DHL_INTERNATIONAL',
  'WIZMO',
  'SHIPSTATION',
];

const OPERATION_VENDORS= [
  'CDW',
  'ADT_COMMERCIAL',
  'AMAZON_BUSINESS',
]

const ORDER_VENDORS= [
  'SHOPIFY',
  'NETSUITE',
  'LOOKER',
]

function getDispOperationValue(val) {
  const mappings = {
    CDW: 'CDW (pdf)',
    ADT_COMMERCIAL: 'ADT Commercial (pdf)',
    AMAZON_BUSINESS: 'Amazon Business (pdf)',
  };

  return mappings[val] || val;
}

const CURRENCY = [
  'USD',
  'CAD',
  'EUR',
  'AAD',
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ANG',
  'AOA',
  'ARS',
  'AUD',
  'AWG',
  'AZN',
  'BAM',
  'BBD',
  'BDT',
  'BGN',
  'BHD',
  'BIF',
  'BMD',
  'BND',
  'BOB',
  'BRL',
  'BSD',
  'BWP',
  'BZD',
  'CDF',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CRC',
  'CUP',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EGP',
  'ERN',
  'ETB',
  'FJD',
  'FKP',
  'GEL',
  'GHS',
  'GIP',
  'GMD',
  'GNF',
  'GTQ',
  'GYD',
  'HKD',
  'HNL',
  'HRK',
  'HTG',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'IQD',
  'IRR',
  'ISK',
  'JMD',
  'JOD',
  'JPY',
  'KES',
  'KGS',
  'KHR',
  'KMF',
  'KPW',
  'KRW',
  'KWD',
  'KYD',
  'KZT',
  'LAK',
  'LBP',
  'LKR',
  'LRD',
  'LSL',
  'LYD',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MNT',
  'MOP',
  'MRO',
  'MUR',
  'MVR',
  'MWK',
  'MXN',
  'MYR',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'OMR',
  'PAB',
  'PEN',
  'PGK',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RWF',
  'SAR',
  'SBD',
  'SCR',
  'SDG',
  'SEK',
  'SGD',
  'SHP',
  'SLL',
  'SOS',
  'SRD',
  'SSP',
  'STD',
  'SZL',
  'THB',
  'TJS',
  'TMT',
  'TND',
  'TOP',
  'TRY',
  'TTD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'UYU',
  'UZS',
  'VEF',
  'VND',
  'VUV',
  'WST',
  'XAF',
  'XCD',
  'XOF',
  'XPF',
  'YER',
  'ZAR',
  'ZMW',
  'ZWL',
];

const SUBSCRIPTION = ['DEMO', 'BASIC', 'EXTENDED', 'PREMIUM'];

/**
 * Converts a set of well-known constants to their human-readable display
 * values.
 */
function getDispValue(val) {
  const mappings = {
    // Carriers and vendors
    APC: 'APC',
    CANADA_POST: 'Canada Post',
    DHL_ECOMMERCE: 'DHL eCommerce',
    DHL_EXPRESS: 'DHL Express',
    EASYPOST: 'EasyPost',
    ENDICIA: 'Endicia',
    FEDEX: 'FedEx',
    INTERNATIONAL_BRIDGE: 'International Bridge',
    LANDMARK_GLOBAL: 'Landmark Global',
    LASERSHIP: 'LaserShip',
    LOOMIS_EXPRESS: 'Loomis Express',
    NEWGISTICS: 'Newgistics',
    ONTRAC: 'OnTrac',
    OSM: 'OSM',
    PITNEY_BOWES: 'Pitney Bowes',
    POSTAGE_ONE: 'Postage One',
    PUROLATOR: 'Purolator',
    SHIPPO: 'Shippo',
    STAMPSCOM: 'Stamps.com',
    USPS: 'USPS',
    UPS: 'UPS',
    ACI: 'ACI',
    CDL: 'CDL',
    GLS: 'GLS',
    QUIET_FEDEX: 'Quiet - FedEx',
    UPS_CANADA: 'UPS - Canada',
    FEDEX_CANADA: 'FedEx - Canada',
    DHL_EXPRESS_CANADA: 'DHL Express Canada',
    DHL_ECOMMERCE_CANADA: 'DHL eCommerce Canada',
    FLEET_OPTICS: 'FleetOptics',
    UDS: 'UDS',
    JITSU: 'Jitsu',
    VEHO: 'Veho',
    MU: 'MU',
    IN_EXPRESS: 'InExpress',
    FEDEX_INTERNATIONAL: 'FedEx (International)',
    UPS_INTERNATIONAL: 'UPS (International)',
    DHL_INTERNATIONAL: 'DHL (Internationa)',
    WIZMO: 'Wizmo',
    SMARTKARGO: 'SmartKargo',
    SHIPSTATION: 'ShipStation',

    // Vendor credentials account types.
    WEBSITE: 'Website',
    FTP: 'FTP',

    // Shipment statuses.
    LABEL_CREATED: 'Label created',
    IN_TRANSIT: 'In transit',
    DELIVERED: 'Delivered',
    TERMINATED_WITHOUT_DELIVERY: 'Terminated without delivery',
    NEVER_SHIPPED: 'Never shipped',
    TRACKING_UPDATES_UNRETRIEVABLE: 'Tracking updates unretrievable',
  };

  return mappings[val] || val;
}

/**
 * Configures the input fields that will appear in a `EditVendorAuth` form for
 * each vendor. Input fields will appear in the order in which they're listed
 * here.
 */
const VENDOR_AUTH_FORM_CONFIG = {
  APC: [
    {
      header: 'FTP login',
      credentialsType: 'ftpCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Username', key: 'ftpCredentials.username', type: 'text' },
        { label: 'Password', key: 'ftpCredentials.password', type: 'password' },
      ],
    },
  ],
  DHL_ECOMMERCE: [
    {
      header: 'Website login',
      credentialsType: 'webCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Email', key: 'webCredentials.username', type: 'text' },
        { label: 'Password', key: 'webCredentials.password', type: 'password' },
      ],
    },
    {
      header: 'FTP login',
      credentialsType: 'ftpCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Username', key: 'ftpCredentials.username', type: 'text' },
        { label: 'Password', key: 'ftpCredentials.password', type: 'password' },
      ],
    },
    {
      header: 'API credentials',
      credentialsType: 'apiCredentials',
      previewField: 'clientId',
      inputs: [
        { label: 'Client ID', key: 'apiCredentials.clientId', type: 'text' },
        { label: 'Client Secret', key: 'apiCredentials.clientSecret', type: 'text' },
        { label: 'Username', key: 'apiCredentials.username', type: 'text' },
        { label: 'Password', key: 'apiCredentials.password', type: 'password' },
      ],
    },
  ],
  DHL_ECOMMERCE_CANADA: [
    {
      header: 'Website login',
      credentialsType: 'webCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Email', key: 'webCredentials.username', type: 'text' },
        { label: 'Password', key: 'webCredentials.password', type: 'password' },
      ],
    },
    {
      header: 'FTP login',
      credentialsType: 'ftpCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Username', key: 'ftpCredentials.username', type: 'text' },
        { label: 'Password', key: 'ftpCredentials.password', type: 'password' },
      ],
    },
    {
      header: 'API credentials',
      credentialsType: 'apiCredentials',
      previewField: 'clientId',
      inputs: [
        { label: 'Client ID', key: 'apiCredentials.clientId', type: 'text' },
        { label: 'Client Secret', key: 'apiCredentials.clientSecret', type: 'text' },
        { label: 'Username', key: 'apiCredentials.username', type: 'text' },
        { label: 'Password', key: 'apiCredentials.password', type: 'password' },
      ],
    },
  ],
  DHL_EXPRESS: [
    {
      header: 'Website login',
      credentialsType: 'webCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Username', key: 'webCredentials.username', type: 'text' },
        { label: 'Password', key: 'webCredentials.password', type: 'password' },
      ],
    },
    {
      header: 'API credentials',
      credentialsType: 'apiCredentials',
      previewField: 'siteId',
      inputs: [
        { label: 'Site ID', key: 'apiCredentials.siteId', type: 'text' },
        { label: 'Password', key: 'apiCredentials.password', type: 'password' },
      ],
    },
  ],
  DHL_EXPRESS_CANADA: [
    {
      header: 'Website login',
      credentialsType: 'webCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Username', key: 'webCredentials.username', type: 'text' },
        { label: 'Password', key: 'webCredentials.password', type: 'password' },
      ],
    },
    {
      header: 'API credentials',
      credentialsType: 'apiCredentials',
      previewField: 'siteId',
      inputs: [
        { label: 'Site ID', key: 'apiCredentials.siteId', type: 'text' },
        { label: 'Password', key: 'apiCredentials.password', type: 'password' },
      ],
    },
  ],
  FLEET_OPTICS: [
    {
      header: 'Website login',
      credentialsType: 'webCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Username', key: 'webCredentials.username', type: 'text' },
        { label: 'Password', key: 'webCredentials.password', type: 'password' },
      ],
    },
  ],
  EASYPOST: [
    {
      header: 'Website login',
      credentialsType: 'webCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Email', key: 'webCredentials.username', type: 'text' },
        { label: 'Password', key: 'webCredentials.password', type: 'password' },
      ],
    },
    {
      header: 'API credentials',
      credentialsType: 'apiCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Username', key: 'apiCredentials.username', type: 'text' },
        { label: 'API key', key: 'apiCredentials.apiKey', type: 'password' },
      ],
    },
  ],
  INTERNATIONAL_BRIDGE: [
    {
      header: 'FTP login',
      credentialsType: 'ftpCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Username', key: 'ftpCredentials.username', type: 'text' },
        { label: 'Password', key: 'ftpCredentials.password', type: 'password' },
      ],
    },
    {
      header: 'API credentials',
      credentialsType: 'apiCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Username', key: 'apiCredentials.username', type: 'text' },
        { label: 'Client key', key: 'apiCredentials.clientKey', type: 'password' },
        { label: 'Client secret', key: 'apiCredentials.clientSecret', type: 'password' },
      ],
    },
  ],
  LANDMARK_GLOBAL: [
    {
      header: 'FTP login',
      credentialsType: 'ftpCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Username', key: 'ftpCredentials.username', type: 'text' },
        { label: 'Password', key: 'ftpCredentials.password', type: 'password' },
      ],
    },
    {
      header: 'API credentials',
      credentialsType: 'apiCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Username', key: 'apiCredentials.username', type: 'text' },
        { label: 'Password', key: 'apiCredentials.password', type: 'password' },
      ],
    },
  ],
  ONTRAC: [
    {
      header: 'API credentials',
      credentialsType: 'apiCredentials',
      previewField: 'account',
      inputs: [
        { label: 'Account', key: 'apiCredentials.account', type: 'text' },
        { label: 'Password', key: 'apiCredentials.password', type: 'password' },
      ],
    },
    {
      header: 'FTP credentials',
      credentialsType: 'ftpCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Username', key: 'ftpCredentials.username', type: 'text' },
        { label: 'Password', key: 'ftpCredentials.password', type: 'password' },
      ],
    },
  ],
  POSTAGE_ONE: [
    {
      header: 'FTP credentials',
      credentialsType: 'ftpCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Username', key: 'ftpCredentials.username', type: 'text' },
        { label: 'Password', key: 'ftpCredentials.password', type: 'password' },
      ],
    },
  ],
  NEWGISTICS: [
    {
      header: 'Website login',
      credentialsType: 'webCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Username', key: 'webCredentials.username', type: 'text' },
        { label: 'Password', key: 'webCredentials.password', type: 'password' },
      ],
    },
    {
      header: 'FTP login',
      credentialsType: 'ftpCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Username', key: 'ftpCredentials.username', type: 'text' },
        { label: 'Password', key: 'ftpCredentials.password', type: 'password' },
      ],
    },
  ],
  SHIPPO: [
    {
      header: 'API credentials',
      credentialsType: 'apiCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Email', key: 'apiCredentials.username', type: 'text' },
        { label: 'API key', key: 'apiCredentials.apiKey', type: 'password' },
      ],
    },
  ],
  VEHO: [
    {
      header: 'Website login',
      credentialsType: 'webCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Username', key: 'webCredentials.username', type: 'text' },
        { label: 'Password', key: 'webCredentials.password', type: 'password' },
      ],
    },
    {
      header: 'API credentials',
      credentialsType: 'apiCredentials',
      previewField: 'apiKey',
      inputs: [
        { label: 'API Key', key: 'apiCredentials.apiKey', type: 'text' },
      ],
    },
  ],
  _default: [
    {
      header: 'Website login',
      credentialsType: 'webCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Username', key: 'webCredentials.username', type: 'text' },
        { label: 'Password', key: 'webCredentials.password', type: 'password' },
      ],
    },
  ],
};

const SUDO_VENDOR_AUTH_FORM_CONFIG = Object.assign({}, VENDOR_AUTH_FORM_CONFIG, {
  FEDEX: [
    {
      header: 'Website login',
      credentialsType: 'webCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Username', key: 'webCredentials.username', type: 'text' },
        { label: 'Password', key: 'webCredentials.password', type: 'password' },
      ],
    },
    {
      header: 'API credentials',
      credentialsType: 'apiCredentials',
      previewField: 'meterNumber',
      inputs: [
        { label: 'Track API key', key: 'apiCredentials.trackApiKey', type: 'text' },
        { label: 'Track Secret key', key: 'apiCredentials.trackSecretKey', type: 'text' },
        { label: 'TNT API key', key: 'apiCredentials.glnApiKey', type: 'text' },
        { label: 'TNT Secret key', key: 'apiCredentials.glnSecretKey', type: 'text' },
        { label: 'Current API Account number', key: 'apiCredentials.accountNumber', type: 'text' },
        { label: 'Meter number (old)', key: 'apiCredentials.meterNumber', type: 'text' },
        { label: 'API key (old)', key: 'apiCredentials.apiKey', type: 'text' },
        { label: 'API password (old)', key: 'apiCredentials.apiPassword', type: 'text' },
      ],
    },
  ],
  FEDEX_CANADA: [
    {
      header: 'Website login',
      credentialsType: 'webCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Username', key: 'webCredentials.username', type: 'text' },
        { label: 'Password', key: 'webCredentials.password', type: 'password' },
      ],
    },
    {
      header: 'API credentials',
      credentialsType: 'apiCredentials',
      previewField: 'meterNumber',
      inputs: [
        { label: 'Track API key', key: 'apiCredentials.trackApiKey', type: 'text' },
        { label: 'Track Secret key', key: 'apiCredentials.trackSecretKey', type: 'text' },
        { label: 'TNT API key', key: 'apiCredentials.glnApiKey', type: 'text' },
        { label: 'TNT Secret key', key: 'apiCredentials.glnSecretKey', type: 'text' },
        { label: 'Current API Account number', key: 'apiCredentials.accountNumber', type: 'text' },
        { label: 'Meter number (old)', key: 'apiCredentials.meterNumber', type: 'text' },
        { label: 'API key (old)', key: 'apiCredentials.apiKey', type: 'text' },
        { label: 'API password (old)', key: 'apiCredentials.apiPassword', type: 'text' },
      ],
    },
  ],
  FEDEX_INTERNATIONAL: [
    {
      header: 'Website login',
      credentialsType: 'webCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Username', key: 'webCredentials.username', type: 'text' },
        { label: 'Password', key: 'webCredentials.password', type: 'password' },
      ],
    },
    {
      header: 'API credentials',
      credentialsType: 'apiCredentials',
      previewField: 'meterNumber',
      inputs: [
        { label: 'Track API key', key: 'apiCredentials.trackApiKey', type: 'text' },
        { label: 'Track Secret key', key: 'apiCredentials.trackSecretKey', type: 'text' },
        { label: 'TNT API key', key: 'apiCredentials.glnApiKey', type: 'text' },
        { label: 'TNT Secret key', key: 'apiCredentials.glnSecretKey', type: 'text' },
        { label: 'Current API Account number', key: 'apiCredentials.accountNumber', type: 'text' },
        { label: 'Meter number (old)', key: 'apiCredentials.meterNumber', type: 'text' },
        { label: 'API key (old)', key: 'apiCredentials.apiKey', type: 'text' },
        { label: 'API password (old)', key: 'apiCredentials.apiPassword', type: 'text' },
      ],
    },
  ],
  SHIPPO: [
    {
      header: 'API credentials',
      credentialsType: 'apiCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Email', key: 'apiCredentials.username', type: 'text' },
        { label: 'API key', key: 'apiCredentials.apiKey', type: 'text' },
      ],
    },
  ],
  UPS: [
    {
      header: 'Website login',
      credentialsType: 'webCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Username', key: 'webCredentials.username', type: 'text' },
        { label: 'Password', key: 'webCredentials.password', type: 'password' },
      ],
    },
    {
      header: 'API credentials',
      credentialsType: 'apiCredentials',
      previewField: 'apiKey',
      inputs: [
        { label: 'API key', key: 'apiCredentials.apiKey', type: 'text' },
        { label: 'Client ID', key: 'apiCredentials.clientId', type: 'text' },
        { label: 'Client Secret', key: 'apiCredentials.clientSecret', type: 'text' },
      ],
    },
  ],
  UPS_CANADA: [
    {
      header: 'Website login',
      credentialsType: 'webCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Username', key: 'webCredentials.username', type: 'text' },
        { label: 'Password', key: 'webCredentials.password', type: 'password' },
      ],
    },
    {
      header: 'API credentials',
      credentialsType: 'apiCredentials',
      previewField: 'apiKey',
      inputs: [
        { label: 'API key', key: 'apiCredentials.apiKey', type: 'text' },
        { label: 'Client ID', key: 'apiCredentials.clientId', type: 'text' },
        { label: 'Client Secret', key: 'apiCredentials.clientSecret', type: 'text' },
      ],
    },
  ],
  UPS_INTERNATIONAL: [
    {
      header: 'Website login',
      credentialsType: 'webCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Username', key: 'webCredentials.username', type: 'text' },
        { label: 'Password', key: 'webCredentials.password', type: 'password' },
      ],
    },
    {
      header: 'API credentials',
      credentialsType: 'apiCredentials',
      previewField: 'apiKey',
      inputs: [
        { label: 'API key', key: 'apiCredentials.apiKey', type: 'text' },
        { label: 'Client ID', key: 'apiCredentials.clientId', type: 'text' },
        { label: 'Client Secret', key: 'apiCredentials.clientSecret', type: 'text' },
      ],
    },
  ],
  USPS: [
    {
      header: 'Website login',
      credentialsType: 'webCredentials',
      previewField: 'username',
      inputs: [
        { label: 'Username', key: 'webCredentials.username', type: 'text' },
        { label: 'Password', key: 'webCredentials.password', type: 'password' },
      ],
    },
    {
      header: 'API credentials',
      credentialsType: 'apiCredentials',
      previewField: 'userId',
      inputs: [{ label: 'User ID', key: 'apiCredentials.userId', type: 'text' }],
    },
  ],
});

const VENDOR_INVOICES_STATUSES = {
  EXCEPTION: 'EXCEPTION',
  UNKNOWN: 'UNKNOWN',
  UNPAID: 'UNPAID',
  TRANSFER_IN_PROGRESS: 'TRANSFER_IN_PROGRESS',
  PAID_UNCONFIRMED: 'PAID_UNCONFIRMED',
  PAID_CONFIRMED: 'PAID_CONFIRMED',
};

const ERROR_MESSAGES = {
  NO_DATE_RANGE_PROVIDED: 'No date range provided.',
};

export const VENDOR_INVOICES_STATUS_LABELS = {
  [VENDOR_INVOICES_STATUSES.UNKNOWN]: {
    label: 'Unknown',
    value: 'UNKNOWN',
  },
  [VENDOR_INVOICES_STATUSES.UNPAID]: {
    label: 'Unpaid',
    value: 'UNPAID',
  },
  [VENDOR_INVOICES_STATUSES.TRANSFER_IN_PROGRESS]: {
    label: 'Transfer in progress',
    value: 'TRANSFER_IN_PROGRESS',
  },
  [VENDOR_INVOICES_STATUSES.PAID_UNCONFIRMED]: {
    label: 'Paid (unconfirmed)',
    value: 'PAID_UNCONFIRMED',
  },
  [VENDOR_INVOICES_STATUSES.PAID_CONFIRMED]: {
    label: 'Paid (confirmed)',
    value: 'PAID_CONFIRMED',
  },
  [VENDOR_INVOICES_STATUSES.EXCEPTION]: {
    label: 'Exception',
    value: 'EXCEPTION',
  },
};

/**
 * Helper function that returns the input config for the given vendor.
 */
function getVendorAuthFormConfig(vendor) {
  return vendor in VENDOR_AUTH_FORM_CONFIG
    ? VENDOR_AUTH_FORM_CONFIG[vendor]
    : VENDOR_AUTH_FORM_CONFIG['_default'];
}

/**
 * Helper function that returns the input config for the given vendor.
 */
function getSudoVendorAuthFormConfig(vendor) {
  return vendor in SUDO_VENDOR_AUTH_FORM_CONFIG
    ? SUDO_VENDOR_AUTH_FORM_CONFIG[vendor]
    : SUDO_VENDOR_AUTH_FORM_CONFIG['_default'];
}

export {
  MDASH,
  VENDOR_INVOICES_STATUSES,
  VENDORS,
  CURRENCY,
  SUBSCRIPTION,
  ERROR_MESSAGES,
  getDispValue,
  VENDOR_AUTH_FORM_CONFIG,
  getVendorAuthFormConfig,
  getSudoVendorAuthFormConfig,
  OPERATION_VENDORS,
  ORDER_VENDORS,
  getDispOperationValue
};

export const CALCULATED_PRICE = 'calculatedPrices';
export const CALCULATED_EFFECTIVENESS = 'calculatedEffectiveness';
export const CALCULATION_COMPARE = 'calculatedCompare';

export const CALCULATION_A = 0;
export const CALCULATION_B = 1;

export const ACCESSORIALS = 'accessorials';
export const DIM = 'DIMDivisor';

export const ALERT_TYPE_LIST = [
  {
    label: 'Info',
    value: 'INFO',
  },

  {
    label: 'Warning',
    value: 'WARNING',
  },

  {
    label: 'Error',
    value: 'ERROR',
  },

  {
    label: 'Critical',
    value: 'CRITICAL',
  },
];
