'use strict';

import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { enqueueSnackbar } from 'notistack';

import * as ajax from 'common/helpers/ajax';
import Button from 'common/components/Button';
import ReduxFormFloatingLabelFormGroup from 'common/components/form/ReduxFormFloatingLabelFormGroup';
import AuthPageContainer from 'auth/containers/AuthPageContainer';

const ResetPasswordRequest = (props) => {
  return (
    <AuthPageContainer contentClassName="ResetPasswordRequest">
      <ResetPasswordRequestForm
        partner={props.partner}
        onSubmit={(data) => props.dispatch(resetPasswordRequest(data))}
      />
    </AuthPageContainer>
  );
};

let ResetPasswordRequestForm = (props) => {
  return (
    <form
      className="ResetPasswordRequestForm defaultColumnFormStyle"
      onSubmit={props.handleSubmit}
    >
      <ReduxFormFloatingLabelFormGroup type="email" name="email" label="Email" />

      <Button
        type="submit"
        size="large"
        variant="contained"
        className={'btn'}
        isLoading={props.submitting}
        disabled={props.pristine || props.submitting}
      >
        Reset password
      </Button>

      <div className="footer-links">
        <a href={'mailto:' + props.partner.supportEmail}>Contact</a>
      </div>
    </form>
  );
};

const resetPasswordRequest = (data) => {
  return (dispatch) => {
    return ajax.postJSON(
      '/api/auth/reset-password-request',
      data,
      (response) => {
        window.location.replace(response.redirect);
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

ResetPasswordRequestForm = reduxForm({
  form: 'Auth.ResetPasswordRequestForm',
})(ResetPasswordRequestForm);

const mapStateToProps = (state) => {
  return {
    partner: state.partner,
  };
};

export default connect(mapStateToProps)(ResetPasswordRequest);
