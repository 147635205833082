import { createTheme } from '@mui/material/styles';

export const themeMui = createTheme({
  typography: {
    // MuiLoadingButton-root:hover
    fontFamily: 'Outfit, sans-serif',
    h1: {
      fontWeight: 300,
    },
    h2: {
      fontWeight: 300,
    },
    h3: {
      fontWeight: 300,
    },
    h4: {
      fontWeight: 300,
    },
    h5: {
      fontWeight: 300,
      fontSize: '24px',
    },
    h6: {
      fontWeight: 300,
    },
    subtitle1: {
      fontWeight: 300,
    },
    body1: {
      fontSize: '14px',
    },
    button: {
      textTransform: 'none',
      fontWeight: 400,
    },
  },

  palette: {
    background: {
      default: '#fafafa',
    },
    primary: {
      main: '#1175DB',
    },
    info: {
      main: '#1175DB',
    },
    error: {
      main: '#bb4f5c',
    },
    text: {
      primary: '#333',
    },
    critical: {
      light: '#ff0000',
      main: '#a40000',
      dark: '#3f0000',
      contrastText: '#ffffff',
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          borderRadius: '25px',
          textAlign: 'center',
          leadingTrim: 'both',
          textEdge: 'cap',
          fontFamily: 'Outfit, sans-serif',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 'normal',
          textTransform: 'capitalize',
          height: 'fit-content',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        contained: {},
        outlined: {
          fontFamily: 'Outfit, sans-serif',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '15px',
          letterSpacing: '0',
          textAlign: 'left',
        },

        sizeMedium: {
          padding: '10px 25px',
        },
        sizeSmall: {
          fontSize: '14px',
          padding: '6px 12px',
        },
        sizeLarge: {
          paddingTop: '15px',
          paddingBottom: '15px',
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        sizeMedium: {
          padding: '10px 25px',
        },
        sizeSmall: {
          fontSize: '14px',
          padding: '6px 12px',
        },
        sizeLarge: {
          paddingTop: '15px',
          paddingBottom: '15px',
        },
      },
    },
  },
});
