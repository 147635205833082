'use strict';

import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

import * as ajax from 'common/helpers/ajax';
import Button from 'common/components/Button';
import ReduxFormFloatingLabelFormGroup from 'common/components/form/ReduxFormFloatingLabelFormGroup';
import AuthPageContainer from 'auth/containers/AuthPageContainer';

const SignIn = (props) => {
  return (
    <AuthPageContainer contentClassName="SignIn">
      <SignInForm onSubmit={(data) => props.dispatch(signIn(data))} />
    </AuthPageContainer>
  );
};

let SignInForm = (props) => {
  return (
    <form className="SignInForm" onSubmit={props.handleSubmit}>
      <div className="SiteLogo">
        <svg
          width="133"
          height="21"
          viewBox="0 0 133 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.148 8.744C22.232 9.22 22.26 9.724 22.26 10.228C22.26 15.716 17.444 20.196 11.48 20.196C5.544 20.196 0.728 15.716 0.728 10.228C0.728 4.712 5.544 0.231999 11.48 0.231999C14.504 0.231999 17.22 1.38 19.18 3.228L16.912 5.3C15.54 3.984 13.608 3.172 11.48 3.172C7.336 3.172 3.948 6.336 3.948 10.228C3.948 14.092 7.336 17.284 11.48 17.284C15.12 17.284 18.144 14.876 18.872 11.684H11.62L14.84 8.744H18.872H22.148ZM34.7804 0.427998L42.5364 20H39.0924L37.2164 15.268H27.8924L26.0164 20H22.6004L30.3284 0.427998H34.7804ZM29.0684 12.328H36.0684L32.5684 3.48L29.0684 12.328ZM48.0467 17.088H58.0987V20.028H44.8267V0.427998H48.0467V17.088ZM63.6731 17.088H73.7251V20.028H60.4531V0.427998H63.6731V17.088ZM89.3515 3.34H79.2995V8.66H88.5955V11.572H79.2995V17.06H89.3515V20H79.2995H76.0795V0.399999H89.3515V3.34ZM102.409 0.231999C108.373 0.231999 113.189 4.712 113.189 10.228C113.189 15.744 108.373 20.224 102.409 20.224C96.4729 20.224 91.6569 15.744 91.6569 10.228C91.6569 4.712 96.4729 0.231999 102.409 0.231999ZM102.409 17.284C106.581 17.284 109.969 14.12 109.969 10.228C109.969 6.336 106.581 3.172 102.409 3.172C98.2649 3.172 94.8769 6.336 94.8769 10.228C94.8769 14.12 98.2649 17.284 102.409 17.284ZM129.1 0.427998H132.348V20H129.296L121.708 9.388C119.776 6.644 118.992 4.852 118.992 4.852C118.992 4.852 119.356 6.868 119.356 10.648V20H116.136V0.427998H119.16L126.748 11.04C128.708 13.784 129.492 15.576 129.492 15.576C129.492 15.576 129.1 13.56 129.1 9.78V0.427998Z"
            fill="white"
          />
        </svg>
      </div>

      <ReduxFormFloatingLabelFormGroup type="email" name="email" label="Email" />

      <ReduxFormFloatingLabelFormGroup type="password" name="password" label="Password" />

      <Button
        type="submit"
        size="large"
        variant="contained"
        className={'btn'}
        isLoading={props.submitting}
        disabled={props.pristine || props.submitting}
      >
        Sign in
      </Button>

      <div className="footer-links">
        <Link to="/reset-password">Reset password</Link>

        <a href={'mailto:' + window.G.partner.supportEmail}>Contact</a>
      </div>
    </form>
  );
};

const signIn = (data) => {
  return (dispatch) => {
    return ajax.postJSON(
      '/api/auth/sign-in',
      data,
      (response) => {
        window.location.replace(response.redirect);
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

SignInForm = reduxForm({
  form: 'Auth.SignInForm',
})(SignInForm);

export default connect()(SignIn);
